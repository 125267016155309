import { createElement, FC, ComponentType, useCallback, useMemo } from 'react';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { ButtonVariant, ButtonBaseProps } from 'legos/button/base-button';
import { ButtonRaised } from 'legos/button/raised';
import { CircularProgress } from 'legos/circular-progress';
import { FormSubmitButton as RawFormSubmitButton } from './form-submit-button.ccm.css';

export interface FormSubmitButtonProps {
  /**
   * The color palette to use
   * @default positive
   */
  color?: ButtonBaseProps['color'];
  /**
   * Sometimes, we want the button to always appear enabled,
   * so users can still feel like they can click it. This is
   * often the case with large or complex forms. If the user
   * misses a field, we want them to be able to click the button
   * and be presented with errors.
   *
   *  Note: Regardless of this being true or false, clicking the button will always
   * "submit" the form. Final Form will still catch this submission and show errors
   *
   * @default false
   */
  appearEnabledWhenInvalid?: boolean;
  /**
   * The button variant that will be rendered
   * @default ButtonRaised
   */
  variant?: ComponentType;
  /**
   * Uses the full width of the parent element.
   * @default false
   */
  fullWidth?: boolean;

  /**
   * Provides border radius to button
   * @default false
   */
  borderRadius?: boolean;
}

export const FormSubmitButton: FC<FormSubmitButtonProps> = ({
  appearEnabledWhenInvalid = false,
  children,
  color = 'positive',
  variant = ButtonRaised,
  fullWidth = false,
  borderRadius = false
}) => {
  const formSpySubscription = useMemo(
    () => ({ submitting: true, hasValidationErrors: true }),
    []
  );

  const ButtonComponent = ButtonVariant;

  const formSpyRenderer = useCallback(
    ({ submitting, hasValidationErrors }: FormSpyRenderProps) => (
      <ButtonComponent
        base={variant}
        variant={RawFormSubmitButton}
        onClick={(event) => submitting && event.preventDefault()}
        enabledAppearance={appearEnabledWhenInvalid}
        disabled={hasValidationErrors}
        color={color}
        type="submit"
        fullWidth={fullWidth}
        borderRadius={borderRadius}
      >
        {submitting ? (
          <CircularProgress size="small" color="white" />
        ) : (
          children
        )}
      </ButtonComponent>
    ),
    [
      variant,
      appearEnabledWhenInvalid,
      borderRadius,
      color,
      fullWidth,
      children
    ]
  );

  return (
    <FormSpy subscription={formSpySubscription} render={formSpyRenderer} />
  );
};
