
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = ``;
styleInject(_css)

/** FormSubmitButton Props */
export type FormSubmitButtonCCM = {
  /** FormSubmitButton Component Custom Properties */
  // No custom properties found

  /** FormSubmitButton Modifier Flags */
  // No modifiers classes found
};
/** Base FormSubmitButton component */
export const FormSubmitButton: ComponentCreator<FormSubmitButtonCCM> = createComponentCreator({
  "name": "FormSubmitButton",
  "base": "FormSubmitButton_57a3f61604382e13",
  "prop": {},
  "mod": {}
});

